<template>
    <div id="inside" style="">
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-header text-light bg-blue" style=""><i class="fa fa-folder-open-o fa-fw mr-2"></i> Meus Acordos</div>
            <div class="row">
                
              </div>

            <div class="card-body" style="">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class=""></h5>
                </div>
              </div>
              <div class="row">
                  Em desenvolvimento
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 col-md-3" style="">
          <ContentMenu></ContentMenu>
        </div>
      </div>
      <div class="row" style="">
        <div class="col-md-12 col-15 my-3" style="">
          <hr> <a class="btn btn-link mx-auto mb-2" @click="voltar"><i class="fa fa-chevron-left fa-fw"></i>Voltar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "MeusAcordos",
    components: {
    },
    data: () => ({
        
    }),
    methods: {

    },
    created() {

    },
    computed: {
    }
};
</script>